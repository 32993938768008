.reviews-page-container{
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
}

.order-review-container{
	margin: 0 auto 0 auto !important;
	background-color: #fff !important;
	border-radius: 10px !important;
	padding: 40px 20px 40px 20px!important;
	max-width: 1100px;
}

.review-cards{
	margin: 0 0 0 0 !important;
	padding: 0 !important;

	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	width: auto;
}

.review-cards .review-cards-col{
	margin: 0 !important;
	padding: 0 !important;
}

.review-cards .review-card{
	border:none !important;
	margin: 0 !important;
	padding: 0 20px 15px 20px !important;
}

.review-cards .review-card .review-card-body{
	padding: 0 !important;
	border-bottom: 1px solid #BEBEBE !important;
	padding-bottom: 5px !important;
}

.review-cards .review-card .review-content-container.container{
	padding: 0!important;
}

.review-cards .review-card .§ .col{
	margin: 0!important;
	padding: 0!important;
}

.review-cards .review-card .review-content-container .row{
	margin: 0 !important;
}

.review-cards .review-card .review-content{
	margin: 0 0 0 0 !important;
	padding: 0 !important;
	font-size: 14px;
}


.review-cards .review-card .reply-button{
	background-color: #F3F3F3;
	width: 100%;
	color: #000;
	text-align: left;
	text-transform: uppercase;
	font-width: bold;
	border:none;
	padding: 10px 40px 10px 40px;
	margin: 10px 0 10px 0 !important;
	font-size: 12px;
	border-radius: 5px;
}

.review-cards .review-card .reply-button:HOVER {
	background-color: #E0E0E0;
}

.review-cards .review-card .reply-button img{
	height: 15px;
	width: 15px;
	margin-right: 5px;
}

.review-cards .review-card .reply-button.disabled{
	color: #777;
}

.review-cards .review-card .reply-button.disabled:HOVER{
	background-color: #F3F3F3;
}

.review-cards .review-card .review-card-logo{
	height: 30px;
	width: 30px;
}

.review-cards .review-card .card-rating{
	text-align: left;
	padding-right: 10px;
	padding-top:1px;
}

.review-cards .review-card  .order-link{
	vertical-align: middle;
	color: black;
	font-weight: bold;
	font-size: 15px;
}

.review-cards .review-card  .order-link:hover{
	text-decoration: underline;
	color: black;
}

.review-cards .review-card .review-content-container .review-date{
	font-size: 13px;
	color: #868686;
	margin-top: 2px;
	margin-bottom: 10px;
}

.review-cards .review-card .reply-container {
	position: relative;
	background: #F3F3F3;
	border-radius: .4em;
	word-break: break-word;
	display: inline-block;
	width: 100%;
	padding: 0;
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 12px;
}

.review-cards .review-card .reply-content{
	padding: 20px 40px 0px 40px;
}

.review-cards .review-card .reply-content h2{
	font-size: 14px;
	margin: 0 0 10px 0;
	font-weight: lighter;
	color: #777;
}

.review-cards .review-card .reply-content-text{
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
	color: black;
	width: 100%;
	font-size: 14px;
}

.review-cards .reply-edit-controls{
	position: absolute;
	top: 5px;
	right: 5px;
}

.review-cards .reply-edit-controls .reply-edit-control{
	background-color: #fff;
	border: none;
	color: #555;
	width: 25px;
	height: 25px;
	border-radius: 12px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 12px;
	margin: 4px 2px;
	cursor: pointer;
	float:left;
	vertical-align: middle;
	line-height: 14px;
	padding: 0px 0 0 0px;
}

.review-cards .reply-edit-controls .reply-edit-control img{
	width: 100%;
	height: 100%;
}

.review-cards .reply-edit-controls .reply-edit-control:HOVER{
	background-color: #DDD;
	color: #000;
}

.review-cards .reply-edit-controls .reply-edit-control.disabled{
	background-color: #E3E3E3;
}

.review-cards .reply-edit-controls .reply-edit-control.disabled:HOVER {
	background-color: #E3E3E3;
}

.review-cards .reply-date{
	font-size: 13px;
	color: #868686;
	padding: 0 10px 5px 0;
	text-align: right;
	margin: 5px 0 0 0;
}

.review-cards .moderation-warning-container{
	padding: 5px 40px 0px 40px;
	color: #777;
}

.review-cards .moderation-warning-container p{
	font-style: italic;
	font-size: 12px;
	overflow: hidden;
}

.review-cards .reply-rejected-warning{
	margin: 15px 0 15px 0 !important;
	background-color: #fff !important;
	border: none !important;
	box-shadow: none !important;
}

.review-cards .reply-rejected-warning p{
	font-size: 13px;
	margin: 0;
	color: #e7131a;
}


.review-cards .reply-rejected-warning a{
	text-decoration: underline;
	color: #e7131a;
	font-weight: bold;
}

.review-cards .reply-rejected-warning a:HOVER{
	color: #d01219;
	margin: 15px 0 15px 0 !important;
	background-color: #fff !important;
	border: none !important;
	box-shadow: none !important;
}


.review-cards .reply-rejected-warning img{
	margin: 0 10px 0 0px;
	width: 30px;
	height: 30px;
}

.review-cards .reply-moderation-message{
	margin: 15px 0 15px 0 !important;
	background-color: #fff !important;
	border: none !important;
	box-shadow: none !important;
}

.review-cards .reply-moderation-message p{
	font-size: 13px;
	margin: 0;
	color: #777;
}

.review-cards .reply-moderation-message img{
	margin: 0 10px 0 0px;
	width: 30px;
	height: 30px;
}

.review-cards .reply-edit-section{
	margin: 10px 0 0 0;
}

.review-cards .submit-reply-info{
	margin-top: 0;
	font-size: 13px;
}

.review-cards .submit-reply-info a{
	color: #000;
	text-decoration: underline;
}

.review-pagination-control{
	width: 100%;
	text-align: center;
	margin-right: 0px !important;
	margin-top: 60px !important;
	margin-bottom: 50px !important;
	justify-content: center !important;
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
}

.review-pagination-control .item{
	color: #868686 !important;
	font-size: 16px !important;
	justify-content: center;
}

.review-pagination-control .item img{
	width: 10px;
	height: 10px;
}

.review-pagination-control .item.active{
	color: #000 !important;
	font-weight: bold !important;
	background-color: transparent !important;
}

.review-pagination-control .item:focus{
	outline: none;
	border: none;
	box-shadow: none;
}

.order-review-controls{
	width: 100%;
	max-width: 1100px;
	padding: 15px 0 15px 0;
	display: flex;
	justify-content: flex-end;
	margin: 0 auto 0 auto;
}

.order-review-controls .review-pagination-items-per-page-control{
	margin-left: 0px !important;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	margin-left:10px !important;
	min-width: 125px !important;
}

.order-review-controls .review-pagination-items-per-page-control:focus{
	outline: none;
	box-shadow: none;
}

.order-review-controls .review-pagination-items-per-page-control.active.dropdown{
	border-color: rgba(34,36,38,.15);
	box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
}

.order-review-controls .review-pagination-items-per-page-control.active.dropdown:hover{
	border-color: rgba(34,36,38,.15);
	box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
}


.order-review-controls .review-pagination-items-per-page-control.active.dropdown .menu {
	border: 1px solid rgba(34,36,38,.15)
}

.order-review-controls .review-pagination-items-per-page-control.active.dropdown:hover .menu{
	border: 1px solid rgba(34,36,38,.15)
}

.reviews-page-container .no-reviews-content{
	margin: 40px auto 40px auto !important;
}

@media (max-width: 991px) {
	.review-chart {
		margin-top: 20px;
	}
}

@media (max-width: 768px) {
	.reviews-page-container{
		padding: 0 15px 0 15px;
	}

	.order-review-container{
		padding: 20px 20px 20px 20px!important;
	}

	.review-cards .review-card{
		padding: 0 0px 15px 0px !important;
	}

	.review-cards .review-card .reply-content {
		padding: 10px 25px 0px 25px;
	}

	.review-cards .reply-date{
		padding: 0 10px 10px 0;
	}

	.review-cards .reply-rejected-warning .icon{
		font-size: 20px !important;
	}

	.review-cards .moderation-warning-container{
		padding: 5px 25px 0px 25px;
	}

	.review-pagination-control{
		margin-top: 0px !important;
	}

	.review-pagination-control .item{
		font-size: 16px !important;
		padding: 5px !important;
		text-align: center !important;
	}

	.review-pagination-control .item img{
		width: 10px;
		height: 10px;
	}

	.review-cards .review-card .reply-button{
		padding: 10px 25px 10px 25px;
	}

}