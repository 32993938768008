.bank-details-item {
    padding-top: 30px!important
}

.bank-details-container{
    padding: 0 20px 0 20px;
}

.bank-details-container .bank-details-jumbotron{
    background-color: #fff;
    border-radius: 5px !important;
    padding-top: 2px;
    padding-bottom: 2px;
    max-width: 1100px;
    margin: 0px auto 0px auto !important;
}

.bank-details-container .bank-details-jumbotron .bank-details-alert{
    background-color: #F3F3F3;
    color: #777;
    border: none;
}