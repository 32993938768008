.App {
  padding:0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  height:100%;
}

.row-nowrap{
  flex-wrap: nowrap !important;
}

a{
  color:#e7131a;
}

a:HOVER{
  color:rgb(138, 14, 16);
  cursor:pointer;
}

.link-button {
  background:none!important;
  border:none;
  padding:0!important;
  font-family:arial,sans-serif; /*input has OS specific font-family*/
  text-decoration:none;
  cursor:pointer;
  outline: none;
}

.link-button:hover{
  cursor:pointer;
  text-decoration: underline;
}

.red-link{
  color:#e7131a;
}

.red-link:focus{
  outline-color: rgb(138, 14, 16);
}

.red-link:hover{
  color:rgb(138, 14, 16);
}

.black-link{
  color: #212529;
}

.black-link:focus{
  outline-color: #0e0f11;
}

.black-link:hover{
  color: #0e0f11;
}

.col-md-1-5 {
  -ms-flex: 0 0 12.50000025%;
      flex: 0 0 12.50000025%;
  max-width: 12.50000025%;
}

.minh-100 {
  min-height: 100%;
  height: 100%;
}

.col-md-10-5 {
  -ms-flex: 0 0 87.500000318181818%;
      flex: 0 0 87.500000318181818%;
  max-width: 87.500000318181818%;        
}

.page-container{
  padding-bottom: 10px;
  height:100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #F6F6F6;
}

.react-bs-table table td, .react-bs-table table th {
  white-space: pre-line;
  text-overflow: unset;
  overflow-wrap: break-word;
}

.summary-title-row{
  position: relative;
  max-width: 1100px;
  margin: 0 auto 0 auto;
}

.summary-title-row .col-sm-12{
  padding: 0 !important;
}

.summary-title-row h1{
  color: #868e96;
  padding:0 50px 0 0;
  margin:0 0 0 0;
  font-size: 1.75rem;
}

.page-content{
  padding: 0 30px 0 30px;
}

.overflow-auto{
  overflow: auto;
}

.vendor-logo{
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.wizard-form{
  padding: 10px 0px 10px 0px;
}

.loading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 49px;
}

.no-margin{
  margin: 0!important;
}

.no-padding{
  padding: 0!important;
}

.navbar-content{

}

.btn-secondary{
  background-color: #F3F3F3;
  color: #000;
  text-transform: uppercase;
  font-width: bold;
  border:none;
  padding: 10px 40px 10px 40px;
  margin: 10px 0 10px 0 !important;
  font-size: 12px;
  border-radius: 5px;
}

.btn-secondary:hover{
  color: #000;
  background-color: #E3E3E3;
}

.btn-secondary:disabled{
  background-color: #F3F3F3;
  color: #777;
  text-transform: uppercase;
  font-width: bold;
  border:none;
  padding: 10px 40px 10px 40px;
  margin: 10px 0 10px 0 !important;
  font-size: 12px;
  border-radius: 5px;
}

.btn-destructive{
  background-color: #e7131a;
  color: #fff;
}

.cancel-button{
  margin-left: 10px !important;
}

.clear{
  clear: both;
}

.modal-header{
  border:none;
  padding: 20px 20px 10px 20px;
}

.modal-header .modal-title{
  font-size: 16px;
}

.modal-body{
  font-size: 16px;
  padding: 0 20px 0 20px;
}

.modal-footer{
  border: none;
  padding: 10px 20px 10px 20px;
}

@media (min-width: 768px) {

  .ml-md-10{
    margin-left: 75px;
  }

  .main-layout-container{
    padding-bottom: 55px;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  body {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
  }

  .page-content{
    padding: 0 0px 0 0px;
  }

  .page-content .row{
      margin-left:10px;
      margin-right:10px;
  }

  .page-content.container{
    max-width: 100%;
  }

  .main-layout-container{
    padding-bottom: 40px;
    overflow: hidden;
  }

  .summary-title-row{
    padding: 10px 20px 10px 20px !important;
  }
}

@media (min-width: 1200px){
  .ml-lg-15{
    margin-left: 150px;
  }
}