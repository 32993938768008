.order-summary-card-wrapper{
    padding: 0 10px 0 10px;
}

.order-summary-card-wrapper:first-child{
    padding: 0 10px 0 0;
}

.order-summary-card-wrapper:last-child{
    padding: 0 0 0 10px;
}

.order-summary-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100%;
}

.order-summary-card .card-body{
    padding: 15px 15px 0 15px;
}

.headline-title{
    font-size: 0.9rem;
    text-align: center;
    color: #B4B4B4;
    margin-top:10px;
}

.headline-value{
    font-size: 1.75rem;
    text-align: center;
    margin: 8px 0 0 0;
    letter-spacing: 1px;
}

.headline-chart .rv-radial-chart{
    margin-left:auto;
    margin-right:auto;
    margin-top:-5px;
}

.headline-chart-legend{
    text-align: center;
    padding-bottom:0px;
}

.headline-chart-legend .headline-chart-legend-block{
    display: inline-block;
    width:15px;
    height:10px;
    background-color:#000;
    margin-right:5px;
    margin-left:15px;
}


.headline-chart-legend .headline-chart-legend-block:first-child{
    margin-left:0;
}

.headline-chart-legend .headline-chart-legend-label{
    font-size: 0.85rem;
    color: #000;
}

.chart-wrapper{
    width:100%;
}

.headline-spark-chart{
    position: absolute;
    left:15px;
    right:15px;
    bottom:0;
}


@media (max-width: 767.98px) {
    .order-summary-card-wrapper, .order-summary-card-wrapper:first-child, .order-summary-card-wrapper:last-child{
        padding: 10px 10px 10px 10px;
    }

    .order-summary-card-wrapper:last-child{
        margin-bottom: 30px;
    }

    .order-summary-container{
        margin-bottom:20px;
    }
    
    .order-summary-container{
        padding-left:5px;
        padding-right:5px;
        padding-bottom:10px;
    }

    .order-summary-container:first-child{
        padding-left:5px;
        padding-right:5px;
    }
    
    .order-summary-container:last-child{
        padding-left:5px;
        padding-right:5px;
    }

    .order-summary-card {
        min-height: 200px;
    }
    
}