.login-container{
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    height:100%;
    background-color: white
}

.login-container .card{
    width:500px;
}

.Login {
    padding: 60px 0;
}

.Login form {
    margin: 0 auto;
    max-width: 320px;
}

.logo-container{
    width:100%;
    text-align: center;
    margin-bottom:20px;
}

.login-logo{
    width:200px;
}