.customer-satisfaction{
    padding-top:20px;
    text-align: center;
}

.customer-satisfaction h3{
    color: #444;
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;
}

.customer-satisfaction .percent-positive{
    font-size: 38px;
    color: #868686;
    margin-top:20px;
    text-align: center;
}

.customer-satisfaction .based-on-text{
    margin-top: 20px;
    color: #444;
}

.customer-satisfaction .not-enough-reviews-text{
    margin-top: 5px;
    color: #444;
}