.summary-section{
    box-shadow: unset !important;
    border: none !important;
    margin: 0 auto 0 auto !important;
    border-radius: 10px !important;
    padding-bottom: 5px;
    max-width: 1100px;
}

.headline-value{
    font-size: 2rem;
    text-align: center;
    letter-spacing: 1px;
    color: #B4B4B4;
}

.headline-title{
    color: #000000;
    font-size: 1.2rem;
    text-align: center;
}