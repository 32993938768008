
.order-date-selection-wrapper .date-sub-heading{
    margin-top:5px;
    padding-top: 0;
    color: #868e96;
}

.date-filter-container .nav-link{
    color: #868e96;
    outline: none;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
    box-shadow: none;

}

.date-filter-container .nav-link.active{
    color: #e7131a;
    background-color: #fff;
    border: 1px solid #e7131a;
    outline: none;
}

.date-filter-container .nav-link.active:HOVER{
    color: rgb(199, 37, 42);
    outline: none;
}

.date-filter-container .nav-link:HOVER{
    color: #595d63;
}

.date-filter .nav-item{
    margin-right: 5px;
    margin-bottom:5px;
}

.date-filter .nav-item:last-of-type{
    margin-right: 0;
}

.custom-date-range-selector-modal .modal-header{
    padding: 10px;
}

.custom-date-range-selector-modal .modal-body{
    padding: 10px;
}

.custom-date-range-selector-modal .modal-title{
    font-size: 1rem;
}

.custom-date-range-selector-modal p{
    text-align: center
}

.custom-date-range-selector-modal .date-range-picker-wrapper{
    text-align: center;
    margin-bottom:10px;
}

.order-table-wrapper{
    min-width: 992px;
    max-width: 1135px;
    margin: 0 auto 0 auto !important;
}

.summary-chart-wrapper{
    max-width: 1135px !important;
    margin: 0 auto 0 auto;
}

.order-date-selection-wrapper{
    margin: 0 auto 0 auto !important;
    max-width: 1135px;
}

.order-activity-spinner-wrapper{
    margin: 0 10px 0 0;
    position: absolute;
    right:0;
    top:62px;
}

.order-activity-spinner-wrapper .order-activity-spinner{
    transform: scale(0.75);
}

.order-activity-spinner-in-button{
    transform: scale(0.6);
    position: absolute;
    top: 0px;
    left: 8px;
}

.summary-title-row .show-charts-button{
    margin: 0 10px 0 0;
    position: absolute;
    right:0;
    top:0;
    color: #868e96;
    border-color: rgba(0, 0, 0, 0.1);
    width:56px;
    height:40px;
}

.summary-title-row .show-charts-button:HOVER{
    color: #868e96;
    border-color: rgba(0, 0, 0, 0.1);
    background-color: transparent;
}

.orders-container{
    padding-left: 20px;
    padding-right: 20px;
}

.orders-container .orders-pagination-control{
    float: right;
    margin-right: 0px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    justify-content: center !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.orders-container .orders-pagination-control.tablet{
    float: none;
    width: 100%;
    text-align: center;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    justify-content: center !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.orders-container .orders-pagination-control .item{
    color: #868686 !important;
    font-size: 16px !important;
    justify-content: center;
}

.orders-container .orders-pagination-control .item img{
    width: 10px;
    height: 10px;
}

.orders-container .orders-pagination-control .item.active{
    color: #000 !important;
    font-weight: bold !important;
    background-color: transparent !important;
}

.orders-container .orders-pagination-control .item:focus{
    outline: none;
    border: none;
    box-shadow: none;
}

.orders-container .items-dropdown.tablet{
    float:right;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 15px;
}

.orders-container .clear{
    clear: both;
}


@media (max-width: 767.98px) {
    .summary-chart-row{
        min-width:100%;
    }

    .date-filter-container{
        padding-left:0 !important;
        padding-right:0 !important;
    }

    .order-table-wrapper{
        min-width: 100%;
    }

    .orders-container{
        padding-left: 0;
        padding-right: 0;
    }
    
}