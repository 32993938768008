.order-table .react-bs-table{
    background-color: #fff;
}

.order-table td.fit, 
.order-table th.fit {
    white-space: nowrap;
    width: 1%;
}

.order-table th, .order-table td{
    font-size: 0.9rem;
}

.order-table .page-item .page-link{
    color:#e7131a;
}

.order-table .page-item.active .page-link{
    background-color: #e7131a;
    border-color:#cc1f27;
    color: #fff;
}

.order-table #paginationDropDown{
    background-color: #fff;
    color:#e7131a;
    border-color: #ddd;
}

.order-table .table-vendor-logo{
    height:30px;
}

.order-table .order-table-icon{
    height:20px;
}

.order-table .order-table-th-icon{
    height:20px;
}

