.vendor_logo {
    height: 57px;
    width: 57px;
    margin: 0 auto 0 auto;
}

.vendor_name {
    font-size: 15px;
    text-align: center;
    width: 100%;
    margin: 15px 0 0 0;
    padding: 0;
    font-weight: bold;
}

.vendor_address {
    font-size: 15px;
    color: #777;
    margin-top: 3px;
}

.vendor-info-grid{
    text-align: center;
    margin: 0;
    position: relative;
}

.vendor-info-grid .vendor-info-grid-content{
    width: 100%;
}

@media (max-width: 767.98px) {
    .vendor_logo{
        height:40px;
        width: 40px;
    }
    .vendor_name {
        font-size: 16px;
    }
    
    .vendor_address {
        font-size: 14px;
        color: #AEAEAE;
        margin-top: 5px;
    }
}