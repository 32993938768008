.invoices-container{
    padding-left: 20px;
    padding-right: 20px;
}

.invoice-table-wrapper{
    padding-top: 0 !important;
    max-width: 1100px;
    margin: 0 auto 0 auto;
}

.invoice-table-segment{
    margin-top: 0 !important;
    padding: 0 !important;

}

.invoices-container .invoices-pagination-control{
    float: right;
    margin-right: 0px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    justify-content: center !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.invoices-container .invoices-pagination-control.tablet{
    float: none;
    width: 100%;
    text-align: center;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    justify-content: center !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.invoices-container .invoices-pagination-control .item{
    color: #868686 !important;
    font-size: 16px !important;
    justify-content: center;
}

.invoices-container .invoices-pagination-control .item img{
    width: 10px;
    height: 10px;
}

.invoices-container .invoices-pagination-control .item.active{
    color: #000 !important;
    font-weight: bold !important;
    background-color: transparent !important;
}

.invoices-container .invoices-pagination-control .item:focus{
    outline: none;
    border: none;
    box-shadow: none;
}



.invoices-container .items-dropdown{
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.invoices-container .items-dropdown.tablet{
    float:right;
    margin-right: 10px;
    margin-top: 0px;
}


button:focus {outline:0;}

@media (max-width: 768px) {
    .invoices-container .invoices-pagination-control .item{
        font-size: 16px !important;
        padding: 5px !important;
        text-align: center !important;
    }

    .invoices-container .invoices-pagination-control .item img{
        width: 10px;
        height: 10px;
    }
}

