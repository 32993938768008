.unimportant-text {
    color: #757575;
}

.important-text {
    color: #E7131A;
}

.rotate {
    animation: loading 3s linear infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}