.vendor-owner-details-item {
    padding-top: 30px!important
}

.vendor-owner-details-container{
    padding: 0 20px 0 20px;
    max-width: 1100px;
    margin: 0 auto 0 auto;

}

.vendor-owner-details-container .vendor-details-container{
    background-color: #fff;
    border-radius: 5px;
    padding: 0 20px 0 20px;
}

.vendor-owner-details-container .vendor-details-container .progress-number{
    margin-bottom: 5px;
}