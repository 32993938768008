.pagination-control{
	width: 100%;
	text-align: center;
	margin-right: 0px !important;
	margin-top: 60px !important;
	margin-bottom: 50px !important;
	justify-content: center !important;
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
}

.pagination-control .item{
	color: #868686 !important;
	font-size: 16px !important;
	justify-content: center;
}

.pagination-control .item img{
	width: 10px;
	height: 10px;
}

.pagination-control .item.active{
	color: #000 !important;
	font-weight: bold !important;
	background-color: transparent !important;
}

.pagination-control .item:focus{
	outline: none;
	border: none;
	box-shadow: none;
}