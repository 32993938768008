.top-nav-bar{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    min-height: 40px;
    padding: 0 10px 0 10px;
    background-color: #fff !important;
    border-bottom: 1px solid rgba(201, 201, 201, 0.4);
}

.navbar .navbar-nav .nav-item{
    line-height: 40px;
}

.navbar button{
    background-color: transparent;
    outline: none;
    border: none;
}

.top-nav-bar .btn-group{
    height:100%;
}

.top-nav-bar .top-bar-nav-link{
    padding-top: 0;
    padding-bottom: 0;
    line-height: 15px;
}

.btn-dropdown-list {
    color: #868e96;
    background-color: #fff !important;
    border-color:transparent;
    display:block;
    position: inherit;
    top: 50%;
    transform: translateY(-50%);
    margin-top:3px;
}

.btn-dropdown-list::after{
    vertical-align: 12px;
}

.caret-off::before {
    display: none !important;
}
.caret-off::after {
    display: none !important;
}

.vendor-logo {
    height: 40px;
}

.overflow-hidden {
    overflow: hidden;
}

.nav-link .text-color-red {
    color: #e7131a !important;
}

.custom-control-label::before, 
.custom-control-label::after {
    top: .8rem;
    width: 1.25rem;
    height: 1.25rem;
}

.cursor-pointer {
    cursor: pointer;
}

@media (max-width: 767.98px) {
    .top-nav-bar{
        position: unset;
        padding: 10px 10px 10px 10px;
    }

    .top-nav-bar .nav-link{
        line-height: 20px;
    }

    .btn-dropdown-list{
        position: inherit;
        display:inline;
        line-height: 20px;
    }
  
    .btn-dropdown-list::after{
      vertical-align: 0px;
    }

    .data-dropdown{
        padding: 5px 0 5px 0;
        margin:0;
    }

    .portal-top-nav .dropdown-menu{
        border:none;
        padding:0;
        margin:0;
    }

    .top-nav-bar .nav-link{
        padding:5px 0 5px 0;
        line-height: unset;
    }

    .portal-top-nav .dropdown-menu .nav-link{
        padding:5px 0 5px 10px;
    }

    .filter-toggler{
        margin-right:5px !important;
    }

    .filter-toggler .navbar-toggler-icon{
        background-image: none !important;
    }

    .filter-toggler .filter-icon{
        padding-left:4px;
    }

    #mobileNavbarToggler .nav-item .nav-link span{
        display: inline-block;
        padding-left: 5px;
        vertical-align: middle;
    }
}

