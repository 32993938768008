.order-detail-col-heading, .order-detail-col-val{
    padding: 0;
}

.order-detail-col-heading{
    max-width:150px;
}

.order-detail-container{
    padding: 0 !important;
    margin:0 !important;
}

.order-detail-row{
    padding: 5px 0 5px 0;
    margin-left: 0px;
    margin-right: 0px;
}

.order-summary-info-card{
    border: none !important;
    box-shadow: none !important;
    margin-bottom: 20px !important;
}

.order-summary-info-card .summary-vendor-logo{
    height:75px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
}

.google-map>div{
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.quantity-badge{
    font-size:0.9rem;
    background-color: #868e96;
}

.order-items-card .list-group-item{
    padding: 0.5rem 1.25rem;
}

.order-items-card .row{
    padding: 0 0 0 0;
}

.order-items-card .quant-col{
    padding: 0;
    min-width: 40px;
    padding-right:10px;
}

.order-items-card .item-name-col{
    padding-left: 0;
}

.order-items-card .sub-item-entry{
    padding-left:2.2rem !important;
}

@media (max-width: 767.98px) {
    .order-detail-col-heading{
        max-width: 100%;
    }
    
    .order-detail-container{
        padding:0;
    }

    .order-detail-col-val{
        text-align: right;
    }
    
    .order-items-card .list-group-item{
        padding: 0.5rem 0.1rem 0.5rem 0.1rem;
    }

    .order-summary-info-card .summary-vendor-logo{
        height:30px;
    }
}
