
.brand-wrapper{
    height:57px;
    border-bottom: 1px solid rgba(201, 201, 201, 0.4);
    padding:5px;
    text-align: center;
}

.top_logo{
    height:25px;
    margin-left: 10px;
}

.logo-lg{
    display: inline-block;
}

.logo-sm{
    display: none;
}

.vertical-dash-nav{
    background-color: #fff;
    height:100%;
    padding: 47px 0 0 0;
    border-right: 1px solid rgba(201, 201, 201, 0.4);
    position: fixed;
    overflow-y: auto;
    z-index: 500;
    transition: all 0.4s ease 0s;
    min-width: 150px;
}

.menu-wrapper{
    padding: 10px 0px 10px 0px;
    margin-top: 20px;
}

.side-menu-section-header{
    color: #C3C3C3;
    letter-spacing: 2px;
    padding: 5px 5px 5px 15px;
    text-align: left;
}

.side-menu-item{
    position: relative;
    padding: 5px 10px 5px 10px
}

.side-menu-item:HOVER{
    background-color: #F5F5F5;
}

.menu-item-selected{
    background-color: #F5F5F5;
    border-right: 3px solid #e7131a;
}

.side-menu-item a{
    padding:0 0 0 0px;
}

.side-menu-link-icon{
   display:inline-block;
}

.side-menu-section-header-text{
    display:inline-block;
    padding:0 0 0 2px;
    vertical-align: middle;
    text-transform: uppercase;
}

.side-menu-link-text{
    display:inline-block;
    padding:0 0 1px 5px;
    vertical-align: middle;
}

@media screen and (max-width: 1200px) {
    .vertical-dash-nav{
        width:75px;
        min-width: 75px;
    }

    .side-menu-link-text{
        display:none;
    }

    .side-menu-item{
        text-align: center;
    }

    .side-menu-section-header-text{
        display:none;
    }

    .side-menu-section-header{
        display:none;
    }

    .logo-lg{
        display: none;
    }
    
    .logo-sm{
        display: inline-block;
    }

    .navbar-brand{
        margin:0;
        padding:0;
    }

    .side-menu-item{
        position: relative;
        padding: 5px 5px 5px 5px;
    }

    .brand-wrapper{
        padding:8px 0 0 0;
        background-color: #e7131a;
    }
}

@media (max-width: 767.98px) {
    .vertical-dash-nav{
        display: none;
    }
    .top_logo{
        height:18px;
        margin-left: 10px;
    }
  }
